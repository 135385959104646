// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const environment = {
  production: false,
  api: 'https://reciclayastagingappservice-api.azurewebsites.net/v1/',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
}; */

export const environment = {
  production: true,
  api: 'https://reciclayaproappservice-api.azurewebsites.net/v1/',
  api_v2: 'https://reciclayaproappservice-api.azurewebsites.net/v2/',
  carrefour_login_url: 'https://carrefour.es/access?source=reciclaya&redirect=https://www.reciclaya.carrefour.es/tabs/tickets/import',
  consent: 'terms 2',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
};